import { SEO } from 'components/blocks/seo';
import Mdx from 'components/containers/mdx';
import styles from 'components/pages/privacy-policy/privacy-policy.module.scss';
import { PageInfo } from 'components/shared/page-info';
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const DataSubProcessors = ({ data }) => {
  const { body: content } = data.file.children[0];

  return (
    <DefaultLayout>
      <PageInfo
        title={'Data Subprocessors'}
        className={styles.pageInfo}
        description={"LoadImpact's subprocessors of Personal Data and Customer Data"}
        withBgPattern
      />

      <div className={'container'}>
        <div className={'row'}>
          <div className={'offset-xl-2 col-xl-8 col-12'}>
            <Mdx className={styles.contentWrapper} content={content} />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    file(name: { eq: "data-sub-processors" }, relativeDirectory: { eq: "markdown" }) {
      children {
        ... on Mdx {
          body
        }
      }
    }
  }
`;

export default DataSubProcessors;

export const Head = () => <SEO {...SEO_DATA['data-sub-processors']} />;
